import _ from 'lodash';

import API from '../../Api';
import history from '../../../history';
import clipboard from '../../../helpers/clipboard';

import deleteDriver from '../../../actions/drivers/deleteDriver';
// import deleteBG from '../Manager/BusinessGroup/deleteBG';
import { apiUrl } from '../../../const';
import exportNameInvoice from '../../../actions/invoices/exportNameInvoice';
import nameInvoice from '../../../actions/invoices/nameInvoice';
import nameQuotation from '../../../actions/quotations/nameQuotation';
import createFromInvoice from '../../../actions/invoices/createFromInvoice';
import createFromQuotation from '../../../actions/invoices/createFromQuotation';
import isManager from '../../../actions/rides/isManager';
import rideToString from '../../../actions/rides/rideToString';
import rideToOrder from '../../../actions/rides/rideToOrder';
import moment from 'moment';

/**
 * Call-To-Action
 * @param {Object} ride Informations de la course
 * @param {Function} refresh Actualise la liste des courses
 */
export default async function CTA(item: Object, refresh: Function) {
  const toastManager = _.get(window, '__react_toast_provider.current');

  const { target } = item;
  const { type, to } = target.value;

  switch (type) {
    case 'delete-ride':
      API.patch(`rides/${to[0]}`, {
        status: 'DELETED',
        notify_passenger: false,
        driver_unit: null
      }).then(() => {
        if (refresh != null) {
          refresh();
        }
        API.post('logs', {
          event: "DELETED",
          ride: to[0],
          user: to[1].first_name + ' ' + to[1].name
        });
      })
        .catch((err) => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
      break;
    case 'link':
      history.push(to);
      break;
    case 'driver_online':
      API.get(`users/${to[0]}?$select[]=driver_unit`).then(res => {
        API.patch(`users/${to[0]}`, {
          ...res.data,
          driver_unit: {
            ...res.data.driver_unit,
            online: to[1]
          }
        }).then(() => {
          toastManager.add(to[1] ? 'Chauffeur mis en ligne' : 'Chauffeur mis hors-ligne', {
            appearance: 'success',
            autoDismiss: true
          });
        })
      })
      break;
    case 'cancel':
      API.patch(`rides/${to}`, {
        status: 'CANCELLED'
      })
        .then(() => {
          if (refresh != null) {
            refresh();
          }
        })
        .catch((err) => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
      break;
    case 'processing':
      API.patch(`rides/${to}`, {
        status: 'PROCESSING',
        driver_unit: null
      })
        .then(() => {
          if (refresh != null) {
            refresh();
          }
        })
        .catch((err) => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
      break;
    case 'medical':
      history.push(to);
      break;
    case 'duplicate-ride':
      API.get(`rides/${to}`).then((resRide) => {
        const newRide = resRide.data;
        delete newRide.createdAt;
        delete newRide.updatedAt;
        delete newRide._id;
        newRide.driver_unit = null;
        newRide.status = 'TO_BE_ASSIGNED';
        newRide.notify_passenger = false;

        API.post("rides", newRide).then(() => {
          if (refresh) refresh();
          toastManager.add('Réservation dupliquée - réinitialiser les filtres pour la voir.', {
            appearance: 'success',
            autoDismiss: true
          });
        })
      });
      break;
    case 'share-email':
      let email = null;
      if (to.passenger) {
        email = to.passenger.email;
      } else {
        const user = await API.get(`rides/${to}?$select[]=passenger`);
        email = user.data.passenger.email;
      }

      API.post('send-email', {
        mail_to: email,
        template_id: 'email_ride',
        ride_id: to.passenger ? to._id : to
      })
        .then(() => {
          toastManager.add('Course partagée par email', {
            appearance: 'success',
            autoDismiss: true
          });
        })
        .catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      break;
    case 'share-sms':
      API.post('send-sms', {
        ride_id: to,
        state: 'sms_infos_manually_trigerred'
      })
        .then(() => {
          toastManager.add('Course partagée par SMS', {
            appearance: 'success',
            autoDismiss: true
          });
        })
        .catch((err) => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
      break;
    case 'transfer':
      to[1](to[0]);
      break;
    case 'clipboard':
      clipboard(to, toastManager);
      break;
    case 'order':
      const text = await rideToOrder(to[0], to[1]);
      clipboard(text, toastManager);
      break;
    case 'dispatch':
      toastManager.add('Course dispatchée à tous les chauffeurs en ligne pour ce service', {
        appearance: 'success',
        autoDismiss: true
      });
      API.post('propose_ride', { ride_id: to }).catch(() => {
        toastManager.add('Erreur dans le dispatch manuel', {
          appearance: 'error'
        });
      });
      break;
    case 'delete-driver':
      deleteDriver(to[0], to[1], refresh, toastManager);
      break;
    case 'delete-dg':
      API.get(`driver-groups/${to}`).then(() => {
        const dg = {};
        dg.archived = true;
        API.patch(`driver-groups/${to}`, dg).then(() => {
          refresh();
        });
      });
      break;
    case 'delete-bg':
      API.get(`driver-groups/${to[1]._id}?$select[]=business_groups`).then((res) => {
        const newBgList = res.data.business_groups.filter((bg) => bg._id !== to[0]);
        API.patch(`driver-groups/${to[1]._id}`, { business_groups: newBgList }).then((res) => {
          refresh(res.data.business_groups);
        });
      });
      break;
    case 'duplicate-bg':
      API.get(`business-groups/${to[0]}`)
        .then((res) => {
          const { data } = res;
          const newbg = {};
          newbg.business_units = data.business_units;
          newbg.driver_groups = data.driver_groups;
          newbg.location = data.location;
          newbg.name = data.name;
          newbg.archived = data.archived;

          API.post('business-groups', newbg)
            .then(() => {
              API.get(`driver-groups/${to[1]._id}`).then((res) => {
                refresh(res.data.business_groups);
              });
            })
            .catch((err) => {
              toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
            });
        })
        .catch((err) => {
          toastManager.add(err.message, { appearance: 'error', autoDismiss: true });
        });
      break;
    case 'quotation-edit':
      history.push(to);
      break;
    case 'quotation-cancel':
      let newQuotation = {};
      newQuotation.status = 'REJECTED';
      API.patch(`quotations/${to}`, newQuotation).then((res) => {
        refresh(res.data);
      });
      break;
    case 'quotation-open':
      let newQuotationO = {};
      newQuotationO.status = 'OPEN';
      API.patch(`quotations/${to}`, newQuotationO).then((res) => {
        refresh(res.data);
      });
      break;
    case 'quotation-confirm':
      let newQuotationC = {};
      newQuotationC.status = 'CONFIRMED';
      API.patch(`quotations/${to}`, newQuotationC).then((res) => {
        refresh(res.data);
      });
      break;
    case 'quotation-duplicate':
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let duplicatedQuotation = {};
      API.get(`quotations/${to}`).then((res) => {
        duplicatedQuotation = res.data;
        delete duplicatedQuotation._id;
        delete duplicatedQuotation.signature;
        delete duplicatedQuotation.caution;
        API.get(`driver-groups/${res.data.driver_group}?$select[]=legal_entities`).then(resDG => {
          API.get(`legal_entities/${resDG.data.legal_entities}?$select[]=quotation_number`).then((res) => {
            duplicatedQuotation.number = year + month + day + '-' + res.data.quotation_number;
            API.post('quotations/', duplicatedQuotation).then(() => {
              refresh(res.data);
            });
          });
        })
      });
      break;
    case 'transform-invoice':
      await createFromQuotation(to[0]._id, toastManager);
      break;
    case 'link-invoice':
      window.open(`${apiUrl}invoices/${to}.pdf`, '_blank');
      //clipboard(`${apiUrl}invoices/${to}.pdf`, toastManager)
      break;
    case 'copy-link-invoice':
      //window.open(`${apiUrl}invoices/${to}.pdf`, '_blank');
      clipboard(`${apiUrl}invoices/${to}.pdf`, toastManager)
      break;
    case 'send-quotation-email':
      // to = [invoice, driver_group, legal_entity]
      //Si on n'a pas toutes les infos du DG on les get
      if (!to[1].support_email || !to[1].support_phone || !to[1].social_networks || !to[1].brand || !to[1].logo) {
        await API.get(
          `driver-groups/${to[1]._id}?$select[]=support_email&$select[]=support_phone&$select[]=brand&$select[]=logo&$select[]=social_networks&$select[]=legal_entities`
        ).then((res) => (to[1] = res.data));
      }
      //Si on n'a pas les infos du legal_entity on les get
      if (!to[2] || !to[2].structure) {
        await API.get(
          `legal_entities/${to[2] && to[2].legal_entities ? to[2].legal_entities : to[1].legal_entities
          }?$select[]=structure&$select[]=address&$select[]=legal_name&$select[]=support_email`
        ).then((res) => (to[2] = res.data));
      }

      API.post('send-email', {
        mail_to: to[0].receiver.email,
        template_id: 'email_quotation',
        attachment: [{ url: apiUrl + 'quotations/' + to[0]._id + '.pdf', name: nameQuotation(to[0]) + '.pdf' }],
        sender: { name: to[1].brand, email: to[1].support_email },
        replyTo: { email: to[1].support_email, name: to[1].brand },
        data: { ...to[0], driver_group: to[1]._id },
        params: {
          DG_NAME: to[1].brand,
          LOGO: apiUrl + 'images/' + to[1].logo,
          SOCIAL_NETWORKS: to[1].social_networks,
          SUPPORT_EMAIL: to[1].support_email,
          SUPPORT_PHONE: to[1].support_phone,
          LEGAL: {
            structure: to[2].structure,
            address: to[2].address,
            legal_name: to[2].legal_name,
            email: to[2].support_email
          }
        }
      })
        .then(() => {
          toastManager.add('Devis envoyé par email', {
            appearance: 'success',
            autoDismiss: true
          });
        })
        .catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      break;
    case 'edit-article':
      history.push(to);
      break;
    case 'delete-article':
      API.patch(`catalogues/${to}`, { archived: true }).then((res) => {
        refresh(res.data);
      });
      break;
    case 'duplicate-article':
      API.get(`catalogues/${to}`).then((res) => {
        const newArticle = res.data;
        delete newArticle.createdAt;
        delete newArticle.updatedAt;
        delete newArticle._id;
        API.post('catalogues', newArticle).then((result) => {
          refresh(result.data);
        });
      });
      break;
    case 'online-booking-email':
      //to = [ride, isWaitingForPayment]
      if (to[0].passenger.email) {
        API.post('send-email', {
          template_id: "email_ride",
          mail_to: to[0].passenger.email,
          sender: { name: to[0].driver_group.brand, email: to[0].driver_group.support_email },
          replyTo: { name: to[0].driver_group.brand, email: to[0].driver_group.support_email },
          cc: to[0].driver_group.support_email,
          ride_id: to[0]._id,
          params: {
            OBJECT: (to[0].status === "QUOTE" && to[1] ? "Réservation en attente de paiement #" + to[0].booking_ref : ["PROCESSING", "CONFIRMED", "TO_BE_ASSIGNED"].findIndex(e => e === to[0].status) > -1 ? "Votre réservation en ligne #" + to[0].booking_ref : "Demande de devis en ligne #" + to[0].booking_ref),
            DG_NAME: to[0].driver_group.brand,
            TITLE: (to[0].status === "QUOTE" && to[1] ? to[0].driver_group.brand + " attend votre paiement en ligne pour confirmer votre réservation." : ["PROCESSING", "CONFIRMED", "TO_BE_ASSIGNED"].findIndex(e => e === to[0].status) > -1 ? "Votre réservation est bien enregistrée." : to[0].driver_group.brand + " va revenir vers vous suite à votre demande de prix."),
            SUPPORT_EMAIL: to[0].driver_group.support_email,
            SUPPORT_PHONE: to[0].driver_group.support_phone
          }
        })
      }
      break;
    case 'share-user-access-email':
      // to = [new_user, driver_group, loggin_user, isDriver]
      API.post('send-email', {
        template_id: 90,
        mail_to: to[0].email,
        sender: { name: to[1].brand, email: to[1].support_email },
        replyTo: { name: to[1].brand, email: to[1].support_email },
        cc: to[1].support_email,
        params: {
          DG_NAME: to[1].brand,
          USER_NAME: to[2].first_name + " " + to[2].name.slice(0, 1) + ".",
          SUPPORT_EMAIL: to[1].support_email,
          SUPPORT_PHONE: to[1].support_phone,
          NEW_USER_EMAIL: to[0].email,
          NEW_USER_PASSWORD: to[0].password,
          IS_DRIVER: to[3],
          IS_BUSINESS: !to[3]
        }
      })
        .then(() => {
          toastManager.add('Accès partagés par email', {
            appearance: 'success',
            autoDismiss: true
          });
        })
        .catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      break;
    case 'unpaid-invoice':
      API.get(`invoices/${to}`).then((res) => {
        let newInvoiceU = {};
        newInvoiceU = res.data;
        newInvoiceU.status = 'OPEN';

        API.patch(`invoices/${to}`, newInvoiceU).then((res) => {
          refresh(res.data);
        }).catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      });
      break;
    case 'paid-invoice':
      // First : SetState - not working properly
      //to[1]({ target: { name: to[0]._id, value: 'PAID' } });
      // Second : Api
      API.get(`invoices/${to[0]}`).then((res) => {
        let newInvoiceP = {};
        newInvoiceP = res.data;
        newInvoiceP.status = 'PAID';

        API.patch(`invoices/${to[0]}`, newInvoiceP).then((res) => {
          refresh(res.data);
        }).catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      });
      break;
    case 'cancel-invoice':
      API.get(`invoices/${to}`).then((res) => {
        let newInvoiceC = {};
        newInvoiceC = res.data;
        newInvoiceC.status = 'CANCELLED';

        API.patch(`invoices/${to}`, newInvoiceC).then((res) => {
          refresh(res.data);
        }).catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      });
      break;
    case 'edit-invoice':
    case 'see-invoice':
      history.push(to);
      break;
    case 'create-invoice':
      // to = [ride._id, paid_client]
      async function createInvoice(to) {
        // * Toutes les requêtes GET
        const ride = await API.get(`rides/${to[0]}`);
        const dg = await API.get(`driver-groups/${ride.data.driver_group._id}?$select[]=legal_entities`);
        const dg_le = await API.get(`legal_entities/${dg.data.legal_entities}`);

        let issue_date = new Date().toISOString().split('T')[0];
        let yearInvoice = issue_date.split('-')[0];
        let monthInvoice = issue_date.split('-')[1];
        let dayInvoice = issue_date.split('-')[2];

        const description = await rideToString(ride.data);
        const invoice = {
          driver_group: ride.data.driver_group,
          number: yearInvoice + monthInvoice + dayInvoice + '-' + dg_le.data.invoice_number,
          total: ride.data.price_client,
          status: ride.data.paid_client || to[1] ? 'PAID' : 'OPEN',
          terms: 'ON_RECEIPT',
          receiver: {
            name: (ride.data.passenger.first_name + " " || "") + (ride.data.passenger.name || ""),
            address: "",
            business_vat_id: "",
            email: ride.data.passenger.email || null
          },
          sender: {
            name: dg_le.data.legal_name,
            address: dg_le.data.address,
            business_vat_id: dg_le.data.business_vat_id,
            business_tax_id: dg_le.data.business_tax_id,
            share_capital: dg_le.data.share_capital,
            rcs_city: dg_le.data.company_register_location,
            structure: dg_le.data.structure,
            mcc: dg_le.data.mcc,
            bank_name: dg_le.data.bank_account.bank_name,
            account_number: dg_le.data.bank_account.account_number,
            bank_identifier_code: dg_le.data.bank_account.bank_identifier_code,
            payment_conditions: dg_le.data.payment_conditions || "En cas de retard de paiement, une pénalité de 3 fois le taux d'intérêt légal sera appliquée, à laquelle s'ajoutera une indemnité forfaitaire pour frais de recouvrement de 40€. Pas d’escompte en cas de paiement anticipé.",
            legal_entity: dg_le.data._id
          },
          items: [
            {
              ride_id: ride.data._id,
              subtotal: (ride.data.price_client / (100 + dg_le.data.tax_rate) * 100).toFixed(2),
              name: ride.data.booking_ref + " - " + moment(ride.data.departure_date).format("DD/MM/YY [à] HH:mm"),
              description: description,
              quantity: 1,
              vat: (ride.data.price_client - (ride.data.price_client / (100 + dg_le.data.tax_rate) * 100)).toFixed(2),
              total: ride.data.price_client,
              tax_percent: dg_le.data.tax_rate
            }
          ],
          issue_date: issue_date,
          automatic_recorey: false,
          client_entry: 'Facturé à bord'
        };

        invoice.title = nameInvoice(invoice);
        invoice.sent = ride.data.passenger.email ? true : false;

        API.post('invoices', invoice)
          .then((res) => {
            toastManager.removeAll();
            toastManager.add('Facture créée', {
              appearance: 'success',
              autoDismiss: true
            });

            if (ride.data.passenger.email) {
              CTA({
                target: {
                  value: {
                    type: 'send-invoice-email',
                    to: [res.data, dg.data, dg_le.data]
                  }
                }
              });
            }
            return res.data;
          })
          .catch((err) => {
            toastManager.add('Erreur non connue, parlez en au service Support', {
              appearance: 'error',
              autoDismiss: true
            });
            return err;
          });
      }

      createInvoice(to);
      break;
    case 'create-ride-self-invoice': // NOT for a DG to self-invoice the subtotal
      // For an external driver to be able to create his own invoice 
      // to = [ride._id, paid_client]
      async function createSelfInvoice(to) {
        // * Toutes les requêtes GET
        const ride = await API.get(`rides/${to[0]}`);
        let unit_le = null;
        if (ride.payment_method_type === 'Stripe') {
          unit_le = await API.get(`legal_entities/${ride.data.driver_group.legal_entities}`);
        } else {
          unit_le = await API.get(`legal_entities/${ride.data.driver_unit.driver_unit.legal_entity}`);
        }

        let issue_date = new Date().toISOString().split('T')[0];
        let yearInvoice = issue_date.split('-')[0];
        let monthInvoice = issue_date.split('-')[1];
        let dayInvoice = issue_date.split('-')[2];

        const description = await rideToString(ride.data);
        const invoice = {
          driver_group: unit_le.data.driver_group, //ride.data.driver_group.legal_entities && ride.data.driver_unit.driver_unit.legal_entity && ride.data.driver_unit.driver_unit.legal_entity === ride.data.driver_group.legal_entities ? ride.data.driver_group._id : null, //This invoice is not issued by a DG
          number: yearInvoice + monthInvoice + dayInvoice + '-' + unit_le.data.invoice_number,
          total: ride.data.price_client,
          status: ride.data.paid_client || to[1] ? 'PAID' : 'OPEN',
          terms: 'ON_RECEIPT',
          receiver: {
            name: (ride.data.passenger.first_name + " " || "") + (ride.data.passenger.name || ""),
            address: "",
            business_vat_id: "",
            email: ride.data.passenger.email || null
          },
          sender: {
            name: unit_le.data.legal_name,
            address: unit_le.data.address,
            business_vat_id: unit_le.data.business_vat_id,
            business_tax_id: unit_le.data.business_tax_id,
            share_capital: unit_le.data.share_capital,
            rcs_city: unit_le.data.company_register_location,
            structure: unit_le.data.structure,
            mcc: unit_le.data.mcc,
            bank_name: unit_le.data.bank_account.bank_name,
            account_number: unit_le.data.bank_account.account_number,
            bank_identifier_code: unit_le.data.bank_account.bank_identifier_code,
            payment_conditions: unit_le.data.payment_conditions || "En cas de retard de paiement, une pénalité de 3 fois le taux d'intérêt légal sera appliquée, à laquelle s'ajoutera une indemnité forfaitaire pour frais de recouvrement de 40€. Pas d’escompte en cas de paiement anticipé.",
            support_email: unit_le.data.support_email || ride.data.driver_unit.email,
            support_phone: ride.data.driver_unit.phone,
            legal_entity: unit_le.data._id
          },
          items: [
            {
              ride_id: ride.data._id,
              subtotal: (ride.data.price_client / (100 + unit_le.data.tax_rate) * 100).toFixed(2),
              name: ride.data.booking_ref + " - " + moment(ride.data.departure_date).format("DD/MM/YY [à] HH:mm"),
              description: description,
              quantity: 1,
              vat: (ride.data.price_client - (ride.data.price_client / (100 + unit_le.data.tax_rate) * 100)).toFixed(2),
              total: ride.data.price_client,
              tax_percent: unit_le.data.tax_rate
            }
          ],
          issue_date: issue_date,
          automatic_recorey: false,
          client_entry: 'Facturé à bord'
        };

        invoice.title = nameInvoice(invoice);
        invoice.sent = to[2] && ride.data.passenger.email ? true : false;

        API.post('invoices', invoice)
          .then((res) => {
            toastManager.removeAll();
            toastManager.add('Facture créée', {
              appearance: 'success',
              autoDismiss: true
            });
            if (to[2] && res.data.receiver.email) {
              API.post('send-email', {
                mail_to: res.data.receiver.email,
                cc: invoice.sender.support_email,
                template_id: 'email_invoice',
                attachment: [{ url: apiUrl + 'invoices/' + res.data._id + '.pdf', name: exportNameInvoice(res.data) + '.pdf' }],
                sender: { name: res.data.sender.name, email: invoice.sender.support_email },
                replyTo: { name: res.data.sender.name, email: invoice.sender.support_email },
                data: { ...res.data, driver_group: null },
                params: {
                  DG_NAME: res.data.sender.name,
                  LOGO: null,
                  SOCIAL_NETWORKS: ride.data.driver_group.social_networks,
                  SUPPORT_EMAIL: invoice.sender.support_email,
                  SUPPORT_PHONE: invoice.sender.support_phone,
                  LEGAL: {
                    structure: res.data.sender.structure,
                    address: res.data.sender.address,
                    legal_name: res.data.sender.name,
                    email: res.data.sender.support_email
                  }
                }
              })
                .then(() => {
                  toastManager.add('Facture envoyée par email', {
                    appearance: 'success',
                    autoDismiss: true
                  });
                })
                .catch(() => {
                  toastManager.add('Facture non envoyée par email', {
                    appearance: 'error',
                    autoDismiss: true
                  });
                })
            }
            return res.data;
          })
          .catch((err) => {
            toastManager.add('Erreur non connue, parlez-en au service Support', {
              appearance: 'error',
              autoDismiss: true
            });
            return err;
          });
      }

      createSelfInvoice(to);
      break;
    case 'recovery-activate':
      API.get(`invoices/${to}`).then((res) => {
        let newInvoiceA = {};
        newInvoiceA = res.data;
        newInvoiceA.automatic_recovery = true;

        API.patch(`invoices/${to}`, newInvoiceA).then((res) => {
          refresh(res.data);
        }).catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      });
      break;
    case 'recovery-desactivate':
      API.get(`invoices/${to}`).then((res) => {
        let newInvoiceR = {};
        newInvoiceR = res.data;
        newInvoiceR.automatic_recovery = false;

        API.patch(`invoices/${to}`, newInvoiceR).then((res) => {
          refresh(res.data);
        }).catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      });
      break;
    case 'duplicate-invoice':
      const resDI = await createFromInvoice(to, toastManager);
      refresh(resDI);
      break;
    case 'credit-invoice':
      CTA({ target: { value: { type: "paid-invoice", to: [to[0], to[1]] } } }, to[1])
      createFromInvoice(to[0], toastManager, "CREDIT").then(() => {
        toastManager.add('Avoir créé et facture mise en payée', {
          appearance: 'success',
          autoDismiss: true
        });
      });
      break;
    case 'send-invoice-email':
      // to = [invoice, driver_group, legal_entity]
      //Si on n'a pas toutes les infos du DG on les get
      if (!to[1].support_email || !to[1].support_phone || !to[1].social_networks || !to[1].brand || !to[1].logo) {
        await API.get(
          `driver-groups/${to[1]._id}?$select[]=support_email&$select[]=support_phone&$select[]=brand&$select[]=logo&$select[]=social_networks&$select[]=legal_entities`
        ).then((res) => (to[1] = res.data));
      }
      //Si on n'a pas les infos du legal_entity on les get
      if (!to[2] || !to[2].structure) {
        await API.get(
          `legal_entities/${to[2] && to[2].legal_entities ? to[2].legal_entities : to[1].legal_entities
          }?$select[]=structure&$select[]=address&$select[]=legal_name&$select[]=support_email`
        ).then((res) => (to[2] = res.data));
      }

      API.post('send-email', {
        mail_to: to[0].receiver.email,
        template_id: 'email_invoice',
        attachment: [{ url: 'https://nebulea.eu/api/invoices/' + to[0]._id + '.pdf', name: exportNameInvoice(to[0]) + '.pdf' }],
        sender: { name: to[1].brand, email: to[1].support_email },
        replyTo: { email: to[1].support_email ? to[1].support_email : to[2].support_email, name: to[1].brand },
        data: { ...to[0], driver_group: to[1]._id },
        params: {
          DG_NAME: to[1].brand,
          LOGO: 'https://nebulea.eu/api/images/' + to[1].logo,
          SOCIAL_NETWORKS: to[1].social_networks,
          SUPPORT_EMAIL: to[1].support_email,
          SUPPORT_PHONE: to[1].support_phone,
          OBJECT: "Facture " + to[0].number,
          LEGAL: {
            structure: to[2].structure,
            address: to[2].address,
            legal_name: to[2].legal_name,
            email: to[2].support_email
          }
        }
      })
        .then(() => {
          toastManager.add('Facture envoyée par email', {
            appearance: 'success',
            autoDismiss: true
          });
          //On patch l'invoice pour marquer qu'on l'a envoyée
          if (!to[0].sent) API.patch(`invoices/${to[0]._id}`, { ...to[0], driver_group: to[1], sent: true }).catch((err) => {
            toastManager.add(err.message, {
              appearance: 'error',
              autoDismiss: true
            });
          })
        })
        .catch((err) => {
          toastManager.add(err.message, {
            appearance: 'error',
            autoDismiss: true
          });
        });
      break;
    case 'chat_state':
      to[2]({ type: to[0], value: to[1] });
      break;
    case 'archive_channel':
      API.patch(`channels/${to[0]}`, { archived: true });
      break;
    default:
      toastManager.add(type, { appearance: 'error', autoDismiss: true });
  }
}
